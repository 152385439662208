<template>
  <Page :classes="['page--vcenter']">

    <form class="auth" v-if="!isDone" v-on:submit.prevent="sendRecoveryRequest">
      <div class="auth__header">
        <h1 class="auth__title">{{$t('auth.recover_password')}}</h1>
      </div>

      <p class="auth__tip">{{$t('auth.recover_password_tip')}}</p>

      <InputEmail
        name="email"
        :label="$t('common.email')"
        v-model.trim="email"
        :focusOnMount="true"
        :isRequired="true"
      ></InputEmail>

      <Button
        :isLoading="isLoading"
        :isDisabled="isInvalid"
        :classes="['btn--accent', 'btn--wide']"
        @onclick="sendRecoveryRequest()">
          <span>{{$t('auth.recover_password')}}</span>
      </Button>

      <p class="auth__link">
        <router-link :to="{name: 'login', params: { locale: $i18n.locale }}">
          {{$t('common.back_to_home')}}
        </router-link>
      </p>

    </form>

    <div v-if="isDone" class="auth">
      <div class="auth__header">
        <h1 class="auth__title">{{$t('auth.recovery_email_sended')}}</h1>
      </div>
      <p class="auth__tip">{{$t('auth.recovery_email_sended_tip')}}</p>

      <p class="auth__link">
        <router-link :to="{name: 'login', params: { locale: $i18n.locale }}">
          {{$t('common.back_to_home')}}
        </router-link>
      </p>
    </div>

  </Page>
</template>

<script>
import Check from '@/services/check/Check';
import InputEmail from '@/components/inputs/email/InputEmail.vue';

export default {
  name: 'RecoverPassword',
  components: {
    InputEmail,
  },
  data() {
    return {
      email: '',
      isLoading: false,
      isDone: false,
    };
  },
  computed: {
    isInvalid() {
      return !this.email.length
        || !Check.isValidEmail(this.email);
    },
  },
  methods: {
    sendRecoveryRequest() {
      this.isLoading = true;

      this.$store.dispatch('recoverPassword', {
        email: this.email,
      }).then(() => {
        this.isDone = true;
      }).finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
